import config from '../../config'
import axios from 'axios';
import { Dispatch } from "redux";
import { CategoryActionTypes } from "../ActionTypes";
const getAllCategory = () => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: CategoryActionTypes.CATEGORY_LIST_REQUEST });
        const { data } = await axios.get(`${config.posUrl}/getAllCategory/${config.merchantId}`);
        dispatch({ type: CategoryActionTypes.CATEGORY_LIST_SUCCESS, payload: data.data });
    } catch (error) {
        dispatch({ type: CategoryActionTypes.CATEGORY_LIST_FAIL, payload: error.message });
    }
};

export { getAllCategory };
