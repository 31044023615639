import config from '../../config'
import axios from 'axios';
import { ItemActionTypes } from "../ActionTypes";
export const getAllItem = () => async (dispatch: any) => {
    try {
        dispatch({ type: ItemActionTypes.ITEM_LIST_REQUEST });
        const { data } = await axios.get(`${config.posUrl}/getAllItem/${config.merchantId}`);
        const variationData = await axios.get(`${config.posUrl}/getAllVariation/${config.merchantId}`);
        const modifierData = await axios.get(`${config.posUrl}/getAllModifier/${config.merchantId}`);
        const taxesData = await axios.get(`${config.posUrl}/getAllTax/${config.merchantId}`);
        var _payload = { items: [], variations: [], modifiers: [], taxes: [] }
        if (data.status !== 'error') {
            _payload = { ..._payload, items: data.data || [] }
        }
        if (variationData.data.status !== 'error') {
            _payload = { ..._payload, variations: variationData.data.data || [] }
        }
        if (modifierData.data.status !== 'error') {
            _payload = { ..._payload, modifiers: modifierData.data.data || [] }
        }
        if (taxesData.data.status !== 'error') {
            _payload = { ..._payload, taxes: taxesData.data.data || [] }
        }
        if (data.status !== 'error') {
            dispatch({ type: ItemActionTypes.ITEM_LIST_SUCESS, payload: { ..._payload } });
        } else {
            dispatch({ type: ItemActionTypes.ITEM_LIST_FAIL, payload: data.message });
        }

    } catch (error) {
        dispatch({ type: ItemActionTypes.ITEM_LIST_FAIL, payload: error.message });
    }
};
export const addTwoPriceItem = (id: string) => (dispatch: any) => {
    dispatch({
        type: ItemActionTypes.ADD_TWO_PRICE_TO_STATE,
        payload: {
            id
        }
    })
}
export const addCategory = (id: string) => (dispatch: any) => {
    dispatch({
        type: ItemActionTypes.ITEM_LIST_ADD_CATEGORY,
        payload: id
    })
}
export const addSearchTerm = (term: string) => (dispatch: any) => {
    dispatch({
        type: ItemActionTypes.ITEM_LIST_SEARCH,
        payload: { term }
    })
}