
export const CartActionTypes = {
    CART_ITEM_ADD: 'CART_ITEM_ADD',
    CART_ITEM_REMOVE: 'CART_ITEM_REMOVE',
    CART_ITEM_SAVE_SHIPPING: 'CART_ITEM_SAVE_SHIPPING',
    CART_ITEM_SAVE_PAYMENT: 'CART_ITEM_uSAVE_PAYMENT',
    CART_ITEM_REMOVE_ALL: 'CART_ITEM_REMOVE_ALL',
    CART_ITEM_REMOVE_SHIPPING: 'CART_ITEM_REMOVE_SHIPPING',
    ADD_INIT_CART_DATA: 'ADD_INIT_CART_DATA'

}
export const PhoneAuthActionTypes = {
    PHONE_AUTH_REQUEST: 'PHONE_AUTH_REQUEST',
    PHONE_AUTH_SUCCESS: 'PHONE_AUTH_SUCCESS',
    PHONE_AUTH_FAILED: 'PHONE_AUTH_FAILED',
    PHONE_AUTH_CLEAN: 'PHONE_AUTH_CLEAN',
}

export const PhoneVerifyActionTypes = {
    PHONE_VERIFY_REQUEST: 'PHONE_VERIFY_REQUEST',
    PHONE_VERIFY_SUCCESS: 'USER_REGISTER_SUCCESS',
    PHONE_VERIFY_FAIL: 'PHONE_VERIFY_FAIL',
    PHONE_VERIFY_CLEAR: 'PHONE_VERIFY_CLEAR',
}

export const ItemActionTypes = {
    ADD_TWO_PRICE_TO_STATE: 'ADD_TWO_PRICE_TO_STATE',
    ITEM_LIST_SUCESS: 'ITEM_LIST_SUCESS',
    ITEM_LIST_FAIL: 'ITEM_LIST_FAIL',
    ITEM_LIST_REQUEST: 'ITEM_LIST_REQUEST',
    ITEM_LIST_SEARCH: 'ITEM_LIST_SEARCH',
    ITEM_LIST_SERACH_CLEAR: 'ITEM_LIST_SERACH_CLEAR',
    ITEM_LIST_ADD_CATEGORY: 'ITEM_LIST_ADD_CATEGORY',
    FETCH_ITEM_SUCCESS: 'FETCH_ITEM_SUCCESS',
    FETCH_ITEM_FAIL: 'FETCH_ITEM_FAIL',
    FETCH_ITEM_REQUEST: 'FETCH_ITEM_REQUEST',
}

export const ShippingActionTypes = {
    ADD_SHIPPING: 'ADD_SHIPPING'
}

export const CategoryActionTypes = {
    CATEGORY_LIST_SUCCESS: 'CATEGORY_LIST_SUCCESS',
    CATEGORY_LIST_FAIL: 'ITEM_LIST_FAIL',
    CATEGORY_LIST_REQUEST: 'ITEM_LIST_REQUEST',
}

export const InitActionTypes = {
    LOADED_INIT: 'LOADED_INIT'
}

export const UtilActionTypes = {
    CART_TOOGLE_ACTION: 'CART_TOOGLE_ACTION',
    MENU_TOOGLE_ACTION: 'MENU_TOOGLE_ACTION'
}
export const MerchantActionTypes = {
    ADD_MERCHANT_CONFIG: 'ADD_MERCHANT_CONFIG'
}