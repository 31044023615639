import { Action, User } from '../store';
import { removeUser, setUser } from '../db';
export const actionTypes = {
    LOGIN_REQUIRE: 'auth/LOGIN_REQUIRE',
    LOGIN_REQUEST: 'auth/LOGIN_REQUEST',
    LOGIN_REQUEST_FAILED: 'auth/LOGIN_REQUEST_FAILED',
    LOGOUT_REQUEST: 'auth/LOGOUT_REQUEST',
    LOGGED_OUT: 'auth/LOGGED_OUT',
    LOGGED_IN: 'auth/LOGGED_IN',
};

export interface LoginRequestPayload {
    name: string;
    phone: string;
    nextRoute: string;
}
const {
    LOGIN_REQUEST,
    LOGIN_REQUEST_FAILED,
    LOGGED_IN,
    LOGOUT_REQUEST,
    LOGGED_OUT,
} = actionTypes;

export function loginRequest(name: string, phone: string) {
    return {
        type: LOGIN_REQUEST,
        payload: { name, phone },
    } as Action<LoginRequestPayload>;
}

export const loggedIn = (user: User) => async (dispatch: any) => {
    await setUser(user)
    dispatch({
        type: LOGGED_IN,
        payload: { user },
    });
}
export function loginFailed(error: string) {
    return {
        type: LOGIN_REQUEST_FAILED,
        payload: { error },
    };
}

export function logOutRequest() {
    return {
        type: LOGOUT_REQUEST,
        payload: {},
    };
}

export const loggedOut = () => async (dispatch: any) => {
    await removeUser()
    dispatch({
        type: LOGGED_OUT,
        payload: {},
    });
}
