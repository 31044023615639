import React, { useState } from 'react';
import logo from '../img/logo2.png'
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { cartToogleAction, addSearchTerm } from "../redux";
import { AppState } from "../store";
import { loggedOut } from "../auth/action";
import { Colors } from "../colors";
import {
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Button,
    CssBaseline,
    InputBase,
    Badge,
    Popover,
} from '@material-ui/core'
import {
    AccountCircle as AccountCircleIcon,
    Search as SearchIcon,
    ShoppingBasketOutlined as ShoppingBasketOutlinedIcon,
    LockOutlined as LockOutlinedIcon,
    ExitToApp
} from '@material-ui/icons'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

function LoginedIcon() {
    const dispatch = useDispatch()
    return (
        <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
                <div>
                    <IconButton arial-label='user accout icon' style={{ color: Colors.primary }} {...bindTrigger(popupState)}>
                        <AccountCircleIcon />
                    </IconButton>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                        <>
                            <Button
                                style={{ color: Colors.primary }}
                                size='small'
                                startIcon={<ExitToApp />}
                                onClick={() => dispatch(loggedOut())}
                            >
                                Logout
                            </Button>
                        </>
                    </Popover>
                </div>
            )
            }
        </PopupState >
    );
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    topbar: {
        background: 'white',
        zIndex: theme.zIndex.drawer + 1,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 15,
        },
    },

    nav_btn_container: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    sectionMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    logo_container: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '8px 2px',
            '& > img': {

                width: 100,
                height: 35,
            }
        },
    },

    searchbox: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #f5f5f5',
        borderRadius: 10,
        padding: '0 10px',
        width: '55%',
        height: 45,
        margin: '0 auto',
        background: '#f3f3f3',
        [theme.breakpoints.down('sm')]: {
            width: '95%',
            height: 40
        }
    },
    search: {
        flexGrow: 1,

    },
    search_div: {
        flexGrow: 1,

    },
    searchIcon: {
        '&:hover': {
            background: 'transparent',
        },
    },
    toolbar: {
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    login: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    shoppincart: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}))

export default function A() {
    const classes = useStyles();
    const history = useHistory()
    const dispatch = useDispatch()
    const [search, setSerach] = useState(null)
    const { CartState, AuthState } = useSelector(state => state) as AppState
    const onChange = (e: any) => {
        setSerach(e.target.value)
    }
    const onSearchHandler = () => {
        if (search) {
            dispatch(addSearchTerm(search || ''))
            history.push('/')
        }
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar className={classes.topbar} elevation={0}>
                <Toolbar className={classes.toolbar}>
                    <div className={classes.logo_container}>
                        <img src={logo}
                            alt='logo'
                            style={{ objectFit: 'contain' }}
                            onClick={() => history.push('/')} />
                        <div className={classes.sectionMobile}>
                            {
                                AuthState.status === 'loggedIn' ?
                                    <LoginedIcon /> :

                                    <Button size='small'
                                        variant='outlined'
                                        onClick={() => history.push('login')}
                                        style={{ textTransform: 'capitalize', borderRadius: 7 }}

                                        startIcon={<LockOutlinedIcon style={{ color: Colors.primary }} />}>Login</Button>
                            }

                        </div>
                    </div>
                    <div className={classes.nav_btn_container}>
                        <div className={classes.search_div}>
                            <div className={classes.searchbox}>
                                <InputBase
                                    className={classes.search}
                                    autoFocus={true}
                                    onChange={e => onChange(e)}
                                    inputProps={{ placeholder: 'Search your products here' }}
                                />
                                <IconButton className={classes.searchIcon} onClick={() => onSearchHandler()}>
                                    <SearchIcon style={{ color: '#bdbdbd' }} />
                                </IconButton>
                            </div>
                        </div>

                        <div className={classes.login}>
                            {
                                AuthState.status === 'loggedIn' ?
                                    <LoginedIcon /> :
                                    <Button
                                        size='small'
                                        variant='outlined'
                                        onClick={() => history.push('login')}
                                        style={{
                                            textTransform: 'capitalize',
                                            borderRadius: 7,
                                        }}
                                        startIcon={<LockOutlinedIcon style={{ color: Colors.primary }} />}
                                    >
                                        Login
                                </Button>
                            }
                        </div>

                        <IconButton aria-label="show cart notifications"
                            className={classes.shoppincart}
                            style={{ color: Colors.primary }}
                            onClick={() => dispatch(cartToogleAction())}
                        >
                            <Badge badgeContent={CartState.items.length} color="secondary" >
                                <ShoppingBasketOutlinedIcon style={{ fontSize: 30 }} />
                            </Badge>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
        </div >
    )

}