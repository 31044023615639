import * as React from 'react'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import { Colors } from "../colors";
export default class App extends React.Component {
    //other logic
    render() {
        return (
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50% , -50%)',
                }}
            >
                <Loader
                    type="Circles"
                    color={Colors.primary}
                    secondaryColor={Colors.secondary}
                    height={100}
                    width={100}
                   // timeout={0} //3 secs
                />
            </div>
        );
    }
}