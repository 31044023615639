import { InitActionTypes } from "../ActionTypes";
import { InitState } from "../../store/state";
const {
    LOADED_INIT
} = InitActionTypes
const defualtState: InitState = {
    loaded: false,
    user: null,
    cartItems: null,
    shipping: null
}
const initReducer = (state: InitState = defualtState, action: any) => {
    switch (action.type) {
        case LOADED_INIT:
            const { user, shipping, cartItems } = action.payload as InitState
            return { loaded: true, user, shipping, cartItems } as InitState;
        default:
            return state as InitState;
    }
}
export {
    initReducer,
};






