import React from 'react';
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { Colors } from "../../colors";
import Grid from '@material-ui/core/Grid';
import { RadioGroup, FormControl, FormControlLabel, FormLabel, Radio } from "@material-ui/core";
interface Props {
    paymentInput: (payment: string) => any,
    shippingInput: (ship: string) => any,
}
export default function PaymentForm(props: Props) {
    const { ShippingState } = useSelector(state => state) as AppState
    const [payment, setPayment] = React.useState('cash');
    const [shipping, setShipping] = React.useState(ShippingState.data[0] ? ShippingState.data[0].amount.toString() : '0');
    const paymentHandler = (e: any) => {
        setPayment(e.target.value)
        props.paymentInput(e.target.value)
    }
    const shippingHandler = (e: any) => {
        setShipping(e.target.value)
        props.shippingInput(e.target.value)
    }
    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Payment Method</FormLabel>
                        <RadioGroup aria-label="payment" name="payment1" value={payment} onChange={(e) => paymentHandler(e)}>
                            <FormControlLabel value="cash" control={<Radio style={{ color: Colors.primary }} />} label="Cash On Delivey" />
                            <FormControlLabel value="bank" control={<Radio style={{ color: Colors.primary }} />} label="Bank Transfer" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Shipping Method</FormLabel>
                        <RadioGroup aria-label="shipping" name="ship1"
                            value={shipping}
                            onChange={(e) => shippingHandler(e)}>
                            {ShippingState.data.map(s => {
                                return (
                                    <FormControlLabel
                                        value={s.amount.toString()}
                                        control={<Radio style={{ color: Colors.primary }} />}
                                        label={`${s.location} ${s.amount === 0 ? 'Free' : s.amount}`} />
                                )
                            })}
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}