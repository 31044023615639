import Axios from "axios";
import config from "../../config";
export const phoneAuth = async (phone: string) => {
    const _url = `${config.posUrl}/phoneAuth/${config.merchantId}/${phone}`
    const { data } = await Axios.get(_url) as
        {
            data: { message: string, status: string, id: string }
        }
    if (data && data.status !== 'error') {
        return {
            success: true,
            id: data.id
        }
    }
    return {
        success: false
    }
}

export const phoneVerify = async (id: string, code: string) => {
    const _url = `${config.posUrl}/phoneVerify/${id}/${code}`
    const { data } = await Axios.get(_url) as { data: { status: string, message: string } }
    if (data && data.status !== 'error') {
        return {
            success: true,
        }
    }
    return {
        success: false,
        message: data.message
    }
}