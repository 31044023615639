import { ItemActionTypes } from "../../redux";
import config from '../../config';
import { Variation, Modifier, Tax, Item, CartItem, ItemState } from "../../store/state";
const cartItems: CartItem[] = []
const defaultState: ItemState = {
    items: cartItems,
    origin: cartItems,
    stateItem: cartItems,
    categoryId: 'All',
    fetched: false,
    error: null
}
const itemListFormat = (items: Item[] = [], variations: Variation[] = [], modifiers: Modifier[] = [], taxes: Tax[] = []) => {
    let key = 0, data: CartItem[] = [], stateItems: CartItem[] = []
    items.forEach(item => {
        let {
            category,
            name,
            itemVariationIds = [],
            applicableTaxIds = [],
            id,
            photo,
            description,
            defaultVariation } = item
        const { enableInventoryControl = false } = defaultVariation
        const [defaultId, defautlPrice] = [defaultVariation.id, defaultVariation.price]
        photo = photo || config.defaultPhoto
        const _list = itemVariationIds.map(varId => {
            var _variation = variations.filter(v => v.id === varId)[0]
            if (_variation) {
                let { name, price, currency, stockQty, unit, points = 0 } = _variation
                if (!enableInventoryControl &&
                    (varId === defaultId) &&
                    defautlPrice > 0) {
                    // make unlimit to default limit
                    stockQty = 20
                }
                if (!_variation.enableInventoryControl &&
                    price > 0) {
                    stockQty = 20
                }
                return {
                    variationName: name,
                    variationId: varId,
                    photo: _variation.photo || photo,
                    applicableTaxIds: applicableTaxIds,
                    taxes: [...taxes],
                    modifiers: [...modifiers],
                    price: price,
                    currency: currency,
                    stockQty: stockQty,
                    unitPoints: points === null ? 0 : points,
                    notValid: true,
                    unit: { ...unit }
                }
            } else {
                return {
                    notValid: false
                }
            }
        })
            .filter(i => i.notValid === true)
            .map(i => {
                key++
                const obj = {
                    ...i,
                    key: key,
                    photo: i.photo,
                    _id: id,
                    name: name,
                    twoPrice: false,
                    description: description === null ? '' : description,
                    category: category && category.name,
                    categoryId: category && category.id,
                }
                return obj as CartItem
            })
        if (_list.length > 1) {
            stateItems.push({ ..._list[0], twoPrice: true, photo: photo })
        } else {
            if (_list.length === 1) stateItems.push({ ..._list[0] })
        }
        data = data.concat([..._list])
    })
    return { origin: data, stateItems: stateItems }
}

const itemReducer = (state: ItemState = defaultState, action: any) => {
    switch (action.type) {
        case ItemActionTypes.ITEM_LIST_REQUEST:
            return { ...state, fetched: false };
        case ItemActionTypes.ITEM_LIST_SUCESS:
            const { items = [], variations = [], modifiers = [], taxes = [] } = action.payload as { items: Item[], variations: Variation[], modifiers: Modifier[], taxes: Tax[] }
            const _formatState = itemListFormat(items, variations, modifiers, taxes)
            return { ...state, fetched: true, items: _formatState.stateItems, origin: _formatState.origin, stateItem: _formatState.stateItems };
        case ItemActionTypes.ITEM_LIST_FAIL:
            return { ...state, loading: false, fetched: true, error: action.payload };
        case ItemActionTypes.ITEM_LIST_ADD_CATEGORY:
            const _catId = action.payload
            const _categoryItems = _catId !== 'All' ?
                state.stateItem.filter(item => item.categoryId === _catId) :
                state.stateItem
            return { ...state, categoryId: action.payload, items: _categoryItems }
        case ItemActionTypes.ADD_TWO_PRICE_TO_STATE:
            const { id } = action.payload as { id: string }
            const _filterItems = state.origin.filter(i => i._id === id).map(v => { return { ...v, twoPrice: false } })
            return { ...state, items: _filterItems }
        case ItemActionTypes.ITEM_LIST_SEARCH:
            var { term } = action.payload
            term = term.replace(/\\$/, "")
            var search = new RegExp(term, 'i')
            const _serchItems = state.categoryId !== 'All' ?
                state.stateItem.filter(item => search.test(item.name) && item.categoryId === state.categoryId) :
                state.stateItem.filter(item => search.test(item.name))
            return { ...state, items: _serchItems }
        case ItemActionTypes.ITEM_LIST_SERACH_CLEAR:
            const _cleanItems = state.categoryId !== 'All' ? state.stateItem.filter(item => item.categoryId === state.categoryId) : state.stateItem
            return { ...state, items: _cleanItems }
        default:
            return state;
    }
}
export { itemReducer }






