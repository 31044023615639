import Axios from "axios";
import config from '../../config'
import {
    MerchantActionTypes
} from "../ActionTypes";
import { Merchant } from "../../store";
export const getMerchant = () => async (dispatch: any) => {
    const { data } = await Axios.get(`${config.posUrl}/getMerchant/${config.merchantId}`) as { data: { status: string, message: string, data: any } }
    let obj = { posPointSystem: 'separate', } as Merchant
    if (data && data.status !== 'error') {
        obj = data.data as Merchant
    }
    dispatch({ type: MerchantActionTypes.ADD_MERCHANT_CONFIG, payload: { ...obj } });
}