import { Action, AuthState, User } from '../store';
import { actionTypes } from "./action";
const {
    LOGIN_REQUEST,
    LOGIN_REQUEST_FAILED,
    LOGGED_IN,
    LOGGED_OUT,
} = actionTypes;

type LoggedAction = Action<{ user: User } | { error: string }>;
const defaultUser = { status: 'none' as 'none' };
export const authReducer = (
    state: AuthState = defaultUser,
    action: LoggedAction
) => {
    switch (action.type) {
        case LOGIN_REQUEST: {
            return {
                status: 'loading',
            };
        }
        case LOGGED_IN:
            const { user } = action.payload as { user: User };
            return {
                status: 'loggedIn',
                user: user,
            };
        case LOGIN_REQUEST_FAILED:
            const { error } = action.payload as { error: string };
            return {
                status: 'error',
                error,
            };
        case LOGGED_OUT:
            return defaultUser;
        default:
            break;
    }
    return state;
};
