import Axios from "axios";
import config from '../../config'
import {
    ShippingActionTypes
} from "../ActionTypes";
export const getShipping = () => async (dispatch: any) => {
    const { data } = await Axios.get(`${config.posUrl}/getShippingFee/${config.merchantId}`) as { data: { status: string, message: string, data: any } }
    let obj = { fee: [] }
    if (data && data.status !== 'error') {
        obj = { ...obj, fee: data.data || [] }
    }
    dispatch({ type: ShippingActionTypes.ADD_SHIPPING, payload: { ...obj } });
}