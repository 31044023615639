import { CategoryActionTypes } from "../ActionTypes";
import { Category, CategoryState } from "../../store/state";
const {
    CATEGORY_LIST_FAIL,
    CATEGORY_LIST_REQUEST,
    CATEGORY_LIST_SUCCESS
} = CategoryActionTypes
const list: Category[] = [{ name: 'All', id: 'All' }]
const defualtState: CategoryState = {
    categories: list,
    fetched: false,
    error: null
}
const categoryReducer = (state: CategoryState = defualtState, action: any) => {
    switch (action.type) {
        case CATEGORY_LIST_REQUEST:
            return { ...state, loading: true, fetched: true };
        case CATEGORY_LIST_SUCCESS:
            const _cats = action.payload || []
            return { loading: false, fetched: true, categories: state.categories.concat(_cats) };
        case CATEGORY_LIST_FAIL:
            return { ...state, loading: false, fetched: true, error: action.payload };
        default:
            return state;
    }
}
export {
    categoryReducer,
};






