import { ShippingActionTypes } from "../ActionTypes";
import { ShippingState, ShippingFee } from "../../store/state";
const {
    ADD_SHIPPING
} = ShippingActionTypes
const list: ShippingFee[] = []
const defualtState: ShippingState = {
    data: list,
    fetched: false
}
export const shippingReducer = (state: ShippingState = defualtState, action: any) => {
    switch (action.type) {
        case ADD_SHIPPING:
            const { fee: f } = action.payload as { fee: ShippingFee[] }
            return { data: f, fetched: true } as ShippingState;
        default:
            return state as ShippingState;
    }
}






