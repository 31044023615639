import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { itemReducer, categoryReducer, cartReducer, utilReducer, merchantReducder, initReducer, shippingReducer } from '../redux';
import { authReducer } from "../auth";
const composeEnhancer = compose;
const reduxCombineReducers: any = combineReducers;
const reducer = reduxCombineReducers({
    ItemState: itemReducer,
    CategoryState: categoryReducer,
    AuthState: authReducer,
    CartState: cartReducer,
    UtilState: utilReducer,
    ShippingState: shippingReducer,
    MerchantState: merchantReducder,
    InitState: initReducer,
});
const store = createStore(reducer,
    composeEnhancer(applyMiddleware(thunk))
);
export default store;
export * from './state'
