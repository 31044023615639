import { UtilActionTypes } from "../ActionTypes";
import { UtilState } from "../../store/state";
const {
    CART_TOOGLE_ACTION,
    MENU_TOOGLE_ACTION
} = UtilActionTypes
const defualtState: UtilState = {
    cartToogleAction: false,
    menuToogleAction: false
}
export const utilReducer = (state: UtilState = defualtState, action: any) => {
    switch (action.type) {
        case CART_TOOGLE_ACTION:
            return { ...state, cartToogleAction: !state.cartToogleAction } as UtilState;
        case MENU_TOOGLE_ACTION:
            return { ...state, menuToogleAction: !state.menuToogleAction } as UtilState;
        default:
            return state as UtilState;
    }
}






