import { UtilActionTypes } from "../ActionTypes";
const {
    CART_TOOGLE_ACTION,
    MENU_TOOGLE_ACTION
} = UtilActionTypes
export const cartToogleAction = () => (dispatch: any) => {
    dispatch({ type: CART_TOOGLE_ACTION, });

};
export const menuToogleAction = () => (dispatch: any) => {
    dispatch({ type: MENU_TOOGLE_ACTION, });

};
