import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useSelector } from "react-redux";
import { AppState } from "../../store";
interface Props {
    addressInput: (address: string) => any,
    cityInput: (city: string) => any,
}
export default function AddressForm(props: Props) {
    const { CartState } = useSelector(state => state) as AppState
    const [state, setState] = React.useState({ address: CartState.shipping?.address || '', city: CartState.shipping?.city || '' })
    const addressHandler = (e: any) => {
        setState({
            ...state,
            address: e.target.value
        })
        props.addressInput(e.target.value)
    }
    const cityHandler = (e: any) => {
        setState({
            ...state,
            city: e.target.value
        })
        props.cityInput(e.target.value)
    }
    return (
        <React.Fragment>
            {/* <Typography variant="h6" gutterBottom>
                Shipping address
      </Typography> */}
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        variant='filled'
                        id="Address"
                        name="address"
                        value={state.address}
                        onChange={(e) => addressHandler(e)}
                        label="Address"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        variant='filled'
                        inputProps={{ vale: 'inputs value' }}
                        id="City"
                        value={state.city}
                        onChange={(e) => cityHandler(e)}
                        name="city"
                        label="City"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

