import { CartActionTypes } from "../ActionTypes";
import { CartItem, CheckoutCartItem, Shipping } from '../../store'
import { removeCartItems } from "../../db";
const {
    CART_ITEM_ADD,
    CART_ITEM_REMOVE,
    CART_ITEM_SAVE_SHIPPING,
    CART_ITEM_SAVE_PAYMENT,
    CART_ITEM_REMOVE_ALL,
    CART_ITEM_REMOVE_SHIPPING,
    ADD_INIT_CART_DATA,
} = CartActionTypes
const addToCart = (item: CartItem) => (dispatch: any) => {
    dispatch({ type: CART_ITEM_ADD, payload: { ...item } });

};
const removeFromCart = (key: number) => (dispatch: any) => {
    dispatch({ type: CART_ITEM_REMOVE, payload: key });

};
const removeAllCart = () => async (dispatch: any) => {
    await removeCartItems()
    dispatch({ type: CART_ITEM_REMOVE_ALL });
}
const saveShipping = (data: Shipping) => (dispatch: any) => {
    dispatch({ type: CART_ITEM_SAVE_SHIPPING, payload: data });
}
const removeShipping = () => (dispatch: any) => {
    dispatch({ type: CART_ITEM_REMOVE_SHIPPING });
}
const savePayment = (data: any) => (dispatch: any) => {
    dispatch({ type: CART_ITEM_SAVE_PAYMENT, payload: data });
}
const addInitShippingAndCartItems = (shipping: Shipping | null, items: CheckoutCartItem[]) => (dispatch: any) => {
    dispatch({
        type: ADD_INIT_CART_DATA,
        payload: {
            shipping,
            items
        }
    })
}

export {
    addToCart,
    removeFromCart,
    savePayment,
    saveShipping,
    removeAllCart,
    removeShipping,
    addInitShippingAndCartItems
};
