import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { AppState } from './store';
import Progress from './loading/loading'
export const initIsLoaded = connectedRouterRedirect({
    redirectPath: '/loading',
    authenticatedSelector: ({ InitState }: AppState) => {
        return InitState.loaded
    },
    authenticatingSelector: ({ InitState }: AppState) =>
        false,
    AuthenticatingComponent: Progress,
    wrapperDisplayName: 'InitLoading',
});
export const userIsAuthenticated = connectedRouterRedirect({
    redirectPath: '/login',
    authenticatedSelector: ({ AuthState }: AppState) => {
        return AuthState && AuthState.status === 'loggedIn'
            ? !!AuthState.user
            : false;
    },
    authenticatingSelector: (state: AppState) =>
        state.AuthState && state.AuthState.status === 'loading',
    AuthenticatingComponent: Progress,
    wrapperDisplayName: 'UserIsAuthenticated',
});