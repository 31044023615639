import * as React from 'react'
import config from "../config";
import logo from '../img/qr.png'
var QRCode = require('qrcode.react');

export default function Q() {
    return (
        <QRCode
            value={config.businessUrl}
            imageSettings={{
                src: logo,
                height: 20,
                width: 20,
            }}
        />

    )
}