import React from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { Colors } from "../colors";
const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        background: 'inherit',
        borderRadius: 6,
        marginBottom: 20,
        marginTop: 120,
        width: '100%',
        height: '100%',
        padding: '30px 50px',
        margin: '20px 0',
    },
}));
const Loading = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CircularProgress size={100} style={{ color: Colors.primary }} />
        </div>
    );
}

export default Loading
