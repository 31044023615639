import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { Button } from "@material-ui/core";
const mapStateToProps = (state: {}) => {
    return {};
};
interface Props extends RouteComponentProps<{}> {
    replace: (path: string) => void
}
const mapDispatchToProps = (dispatch: Dispatch, { history }: Props) => {
    return {
        replace: (path: string) => {
            history.replace(`/${path}`)
        }
    };
}
class Root extends React.Component<Props> {
    render() {
        const { replace } = this.props
        return (
            <>
                Hello From Contact Page
                <Button onClick={() => replace('about')} >Go Back</Button>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Root);
