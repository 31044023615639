import { MerchantActionTypes } from "../ActionTypes";
import { MerchantState, Merchant } from "../../store/state";
const {
    ADD_MERCHANT_CONFIG
} = MerchantActionTypes
const m: Merchant = { posPointSystem: 'separate', }
const defualtState: MerchantState = {
    fetched: false,
    data: m
}
export const merchantReducder = (state: MerchantState = defualtState, action: any) => {
    switch (action.type) {
        case ADD_MERCHANT_CONFIG:
            const m = action.payload as Merchant
            return { data: m, fetched: true } as MerchantState;
        default:
            return state as MerchantState;
    }
}






