import { pseudoRandomBytes } from 'crypto';
const randomLetter = () => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    return alphabet[Math.floor(Math.random() * alphabet.length)]
}
const generateSixDigits = () => {
    const chars = '0123456789';
    const max = Math.floor(256 / chars.length) * chars.length;
    const size = 6;

    let ret = '';
    while (ret.length < size) {
        const buf = pseudoRandomBytes(size - ret.length);
        for (let i = 0; i < buf.length; i++) {
            const x = buf.readUInt8(i);
            if (x < max) {
                ret += chars[x % chars.length];
            }
        }
    }
    return ret;
};
export const generateOrderNumber = () => {
    return randomLetter() + generateSixDigits()
}

export const isMobile = () => {
    return window.matchMedia("(max-width:800px)").matches || false;
}
export const checkFooterIcon = () => {
    return window.matchMedia("(max-width:1279px)").matches || false;
}
export const Iphone5 = () => {
    return window.matchMedia("(max-width:320px)").matches || false;
}