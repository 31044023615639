import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AboutScreen, HomeScreen, CheckoutScreen, Login, Detail } from "./screens";
import { AppBar, Footer, Drawer } from "./component";
import { userIsAuthenticated, initIsLoaded } from "./wrapper";
import { withStyles } from '@material-ui/styles';
const styles = (theme: any) => ({
    main: {
        marginTop: 60,
    },
}
);

const mapStateToProps = (state = {}) => {
    return {};
};

const mapDispatchToProps = (dispatch: any, { history }: Props) => {
    return {
        onMenuClick: (item: { key: string }) => {
            history.push(`/${item.key}`);
        },
    };
};

interface Props extends RouteComponentProps<{}> {
    onMenuClick: (item: { key: string }) => void;
    classes: any;
}

class Root extends React.Component<Props> {
    render() {
        const { classes } = this.props
        return (
            <div className="App">
                <AppBar />
                <main className={classes.main}>
                    <Switch>
                        <Route exact={true} path="/" component={initIsLoaded(HomeScreen)} />
                        <Route path="/login" component={initIsLoaded(Login)} />
                        <Route path="/home" component={HomeScreen} />
                        <Route path="/detail/:productId" component={initIsLoaded(Detail)} />
                        <Route path="/about" component={initIsLoaded(AboutScreen)} />
                        <Route path="/checkout" component={userIsAuthenticated(CheckoutScreen)} />
                    </Switch>
                    <Drawer />
                </main>
                <Footer />
            </div >
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Root)) as any;
