import { CartActionTypes } from "../ActionTypes";
import { CartItem, CheckoutCartItem, CartState, Shipping } from "../../store";
import { setCartItems, setShipping } from "../../db";
const {
    CART_ITEM_ADD,
    CART_ITEM_REMOVE,
    CART_ITEM_SAVE_SHIPPING,
    CART_ITEM_SAVE_PAYMENT,
    CART_ITEM_REMOVE_ALL,
    CART_ITEM_REMOVE_SHIPPING,
    ADD_INIT_CART_DATA
} = CartActionTypes

const list: CheckoutCartItem[] = []
const defaultState: CartState = {
    items: list,
    shipping: null,
    payment: 'cash'
}
const computeItem = (item: CartItem) => {
    let totalAmount = 0, totalNetAmount = 0, addedTaxAmount = 0
    let wholeTaxAmount = 0, inclusiveTaxAmount = 0
    const { taxes = [], qty = 0, price = 0, applicableTaxIds } = item
    totalAmount = price * qty
    let wholeTax = taxes.filter(tax => tax.application === 'whole')[0]
    if (wholeTax && wholeTax.value) {
        wholeTaxAmount = totalAmount * (wholeTax.value / 100)
    }
    if (applicableTaxIds !== null) {
        if (Array.isArray(applicableTaxIds)) {
            applicableTaxIds.forEach(taxId => {
                const inclusiveTax = taxes.filter(tax => tax.id === taxId)[0]
                inclusiveTaxAmount = totalAmount * (inclusiveTax.value / 100)
            })
        }
    }
    addedTaxAmount = wholeTaxAmount + inclusiveTaxAmount
    totalNetAmount = totalAmount + wholeTaxAmount + inclusiveTaxAmount
    return {
        ...item,
        totalAmount: totalAmount,
        totalNetAmount: totalNetAmount,
        addedTaxAmount: addedTaxAmount,
        displayAmount: ` ${totalAmount} + ${wholeTaxAmount} + ${inclusiveTaxAmount}`
    } as CheckoutCartItem

}
const cartReducer = (state: CartState = defaultState, action: any) => {
    switch (action.type) {
        case CART_ITEM_ADD:
            const item = computeItem(action.payload)
            const isExist = state.items.find(i => i.key === item.key);
            if (isExist) {
                var _addExistItems = state.items.map(i => i.key === item.key ? item : i)
                setCartItems(_addExistItems)
                return {
                    ...state,
                    items: _addExistItems
                };
            }
           // console.log('before length', state.items.length)
            setCartItems([...state.items, item])
           // console.log('return lenght', [...state.items, item].length)
            return { ...state, items: [...state.items, item] };
        case CART_ITEM_REMOVE:
            var _rmItems = state.items.filter(item => item.key !== action.payload)
            setCartItems(_rmItems)
            return { ...state, items: _rmItems };
        case ADD_INIT_CART_DATA:
            const { shipping, items } = action.payload as { shipping: Shipping, items: CheckoutCartItem[] }
            return { ...state, items: items, shipping } as CartState
        case CART_ITEM_REMOVE_ALL:
            return { ...state, items: [] } as CartState;
        case CART_ITEM_SAVE_SHIPPING:
            const s = action.payload as Shipping
            setShipping(s)
            return { ...state, shipping: action.payload };
        case CART_ITEM_REMOVE_SHIPPING:
            return { ...state, shipping: null }
        case CART_ITEM_SAVE_PAYMENT:
            return { ...state, payment: action.payload };
        default:
            return state;
    }
}
export {
    cartReducer,
};
