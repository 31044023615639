import { InitActionTypes } from "../ActionTypes";
import { getUser, getCartItems, getShipping } from "../../db";
const {
    LOADED_INIT
} = InitActionTypes
const loadingInit = async () => {
    const user = await getUser()
    const shipping = await getShipping()
    const cartItems = await getCartItems()
    return { user, shipping, cartItems }
}
export const loadedInit = () => async (dispatch: any) => {
    const res = await loadingInit()
    dispatch({
        type: LOADED_INIT,
        payload: {
            user: res.user || null,
            shipping: res.shipping || null,
            cartItems: res.cartItems || null,
        }
    });
};
