import React, { useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { RouteComponentProps } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import { makeStyles, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSelector, useDispatch } from "react-redux";
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { AppState, CartItem } from "../../store";
import { addToCart } from "../../redux";
import { Colors } from "../../colors";
import {
    Typography,
    Button,
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    IconButton
} from '@material-ui/core';
import './style.css';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        paddingBottom: 100,
    },
    detailContainer: {
        background: 'white',
        height: 'calc(100vh - 124px)',
        maxHeight: '100%',
        [theme.breakpoints.down('lg')]: {
            height: 'auto'
        }

    },


    detail: {
        width: 'calc(50% - 1px)',
        maxHeight: '100%',
        float: 'left',
        padding: theme.spacing(5)
    },
    relativeItem: {
        padding: theme.spacing(5)
    },
    icon: {
        padding: 4,
        marginLeft: 4,
        '&:hover': {
            background: '#5a90ee',
            borderRadius: theme.spacing(1, 0, 0, 1)
        }
    },
    iconPlus: {
        marginRight: 4,
        '&:hover': {
            borderRadius: theme.spacing(0, 1, 1, 0)
        }
    },

    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 7,
        transition: 'all 0.3s ease-in-out 0s',
        border: '1px solid rgb(243, 243, 243)',
        cursor: 'pointer',
        '&:hover': {
            transform: 'translateY(-6px)',
            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',

        }
    },

    cardMediaRoot: {
        height: 230,
        padding: 20,
        boxSizing: 'border-box'
    },
    cardMediaImg: {
        width: '100%',
        maxHeight: '100%',
        textAlign: 'center',
        height: '100%',
        '& > img': {
            width: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            height: 'auto'
        }
    },
    cardContent: {
        flexGrow: 1
    },


}));

function D(props: RouteComponentProps) {
    const { productId } = props.match.params as { productId: string }
    const { ItemState } = useSelector(state => state) as AppState
    const items = ItemState.origin.filter(i => i._id === productId)
    const categoryId = items[0].categoryId
    const relatedItem = ItemState.origin.filter(i => i.categoryId === categoryId).filter(i => i._id !== items[0]._id)
    const classes = useStyles();
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [index, setIndex] = useState(0)
    const [rindex, setRindex] = useState(-1)

    const detailAddToCart = (item: CartItem) => {
        setLoading(true)
        dispatch(addToCart({ ...item, qty: 1 }))
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }
    const rAddToCart = (item: CartItem) => {
        setRindex(item.key)
        dispatch(addToCart({ ...item, qty: 1 }))
        setTimeout(() => {
            setRindex(-1)
        }, 1000);
    }
    const onChange = (index: number) => {
        setIndex(index)
    }
    return (
        <div className={classes.root}>
            <div className={classes.detailContainer}>
                <div className='carousel-wrapper'>
                    <Carousel
                        showIndicators={false}
                        showStatus={false}
                        thumbWidth={60}
                        className='carousel'
                        onChange={i => onChange(i)}
                    >
                        {
                            items.map(item => {
                                return (
                                    <div>
                                        <img src={item.photo} alt='img' />
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                </div>
                <div className={classes.detail}>
                    <Typography variant='h5' component='h2' gutterBottom>
                        {items[0].name}
                    </Typography>
                    <Typography variant='h6' component='h3'>
                        {items[index].variationName}
                    </Typography>
                    <Typography variant='h6' component='h3'>
                        {items[index].price} MMK
                    </Typography>
                    <Typography gutterBottom paragraph variant='body2' style={{ margin: '40px 0' }}>
                        {items[index].description}
                    </Typography>

                    <Button
                        onClick={() => detailAddToCart(items[index])}
                        variant='contained'
                        disabled={loading}
                        style={{ background: Colors.primary, color: 'white', textTransform: 'initial', borderRadius: 8, padding: '8px 16px' }}
                        startIcon={
                            loading ? <CircularProgress size={25} /> :
                                <ShoppingBasketIcon />}
                    >
                        Add to cart
                    </Button>
                    {/* <Button
                        variant='contained'
                        style={{ background: '#80A6EA', color: 'white', width: 140, justifyContent: 'space-between', padding: 0, borderRadius: 8 }}
                        startIcon={<div className={classes.icon}><RemoveIcon /></div>}
                        endIcon={<div className={clsx(classes.icon, classes.iconPlus)}><AddIcon /></div>}
                        children={<span style={{ cursor: 'text' }}>1</span>}
                    /> */}
                </div>
                <div style={{ clear: 'both' }} />

            </div>
            <div className={classes.relativeItem}>
                <Typography variant='h5' component='h2' style={{ marginBottom: 30 }}>
                    Related Items
                 </Typography>

                <Grid container spacing={2}>
                    {
                        relatedItem.filter(i => i.stockQty > 0 && i.price > 0).map(item => {
                            return (
                                <Grid item md={3} sm={4}>
                                    <Card className={classes.card} elevation={0}>
                                        <CardMedia
                                            classes={{ root: classes.cardMediaRoot }}
                                            children={
                                                <div className={classes.cardMediaImg}>
                                                    <img src={item.photo} alt='' style={{ borderRadius: 4, objectFit: 'contain' }} />
                                                </div>
                                            }
                                        />
                                        <CardContent className={classes.cardContent} style={{ padding: '0 16px' }}>
                                            <Typography variant='h6' component='h2' gutterBottom>
                                                {item.name}
                                            </Typography>
                                            <Typography variant='subtitle1' component='h2' gutterBottom>
                                                {item.variationName}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Typography style={{ flexGrow: 1, color: Colors.primary, fontWeight: 'bold' }}>
                                                {item.price} MMk
                                       </Typography>
                                            <IconButton
                                                onClick={() => rAddToCart(item)}
                                            >
                                                {
                                                    rindex === item.key ? <CircularProgress size={25} /> : <AddIcon />
                                                }

                                            </IconButton>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )
                        })
                    }

                </Grid>
            </div>
        </div>
    );

}
export default D as any;

