import localforage from 'localforage'
import config from './config';
import { User, CheckoutCartItem, Shipping } from "./store";
localforage.config({
    driver: localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
    name: config.db.name,
    version: 1.0,
    size: 4980736,
    storeName: config.db.storeName, // Should be alphanumeric, with underscores.
    description: config.db.description
});
export const DB_USER_KEY = 'DBUSERKEY'
export const DB_CART_ITEM_KEY = 'DBCARTITEMKEY'
export const DB_SHIPPING_KEY = 'DBSHIPPINGKEY'
export const setUser = async (user: User) => {
    await localforage.setItem(DB_USER_KEY, { ...user })
}
export const getUser = async () => {
    return await localforage.getItem(DB_USER_KEY) as User
}
export const removeUser = async () => {
    return await localforage.removeItem(DB_USER_KEY)
}
export const setCartItems = async (items: CheckoutCartItem[] = []) => {
    localforage.setItem(DB_CART_ITEM_KEY, [...items]).then(v => {
        console.log('set value', v)
    })
}
export const getCartItems = async () => {
    return await localforage.getItem(DB_CART_ITEM_KEY) as CheckoutCartItem[]
}
export const removeCartItems = async () => {
    return await localforage.removeItem(DB_CART_ITEM_KEY)
}
export const setShipping = async (s: Shipping) => {
    await localforage.setItem(DB_SHIPPING_KEY, { ...s })
}
export const removeShipping = async () => {
    return await localforage.removeItem(DB_SHIPPING_KEY)
}
export const getShipping = async () => {
    return await localforage.getItem(DB_SHIPPING_KEY) as Shipping
}
export const clean = async () => {
    return await localforage.clear()
}