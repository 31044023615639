import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../store";
// import Loading from "../loading/loading";
import { Spinner } from "../component";
import { loggedIn } from "../auth/action";
import { getAllItem, getAllCategory, getShipping, loadedInit, addInitShippingAndCartItems, getMerchant } from "../redux";

export default function L() {
    const dispatch = useDispatch(), history = useHistory()
    const [pending, setPending] = React.useState(false)
    const [inited, setInited] = React.useState(false)
    const { ItemState, CategoryState, InitState, ShippingState, MerchantState } = useSelector(state => state) as AppState
    React.useEffect(() => {
        if (!ItemState.fetched && !pending) {
            dispatch(getAllItem())
        }
        if (!CategoryState.fetched && !pending) {
            dispatch(getAllCategory())
        }
        if (!ShippingState.fetched && !pending) {
            dispatch(getShipping())
        }
        if (!InitState.loaded && !pending) {
            dispatch(loadedInit())
        }
        if (!MerchantState.fetched && !pending) {
            dispatch(getMerchant())
        }
        if (!CategoryState.fetched ||
            !ItemState.fetched ||
            !InitState.loaded ||
            !ShippingState.fetched) {
            setPending(true)
        }
        if (CategoryState.fetched &&
            ItemState.fetched &&
            InitState.loaded &&
            ShippingState.fetched) {
            history.push('/')
            setPending(false)
        }
        if (InitState.loaded && !inited) {
            if (InitState.user) {
                dispatch(loggedIn(InitState.user))
            }
            dispatch(addInitShippingAndCartItems(InitState.shipping, InitState.cartItems || []))
            setInited(true)
        }
    }, [setPending,
        CategoryState,
        InitState,
        ItemState,
        MerchantState,
        ShippingState,
        history,
        dispatch,
        pending,
        inited,
    ])
    return (
        <>
            <Spinner />
        </>
    )
}