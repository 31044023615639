import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import store from './store'
import Root from './Root'
import './app.css'
import { LoadingScreen } from "./screens";
class App extends React.Component<{}> {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path='/loading' component={LoadingScreen} />
          <Route path="/" component={Root} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default class RootApp extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <App />
      </Provider>
    );
  }
}
