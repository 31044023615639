import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh'
    },

    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    TextField: {
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none"
        }
    }

}));

// const isMobile = () => {
//     return window.matchMedia("(max-width:800px)").matches || false;
// }
interface Props {
    phoneInput: (phone: string) => any
    nameInput: (name: string) => any
}
export default function SignIn(props: Props) {
    const classes = useStyles();
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <form className={classes.form} noValidate>
                    <TextField
                        margin='dense'
                        variant='filled'
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        onChange={e => props.nameInput(e.target.value)}
                        autoFocus
                    />
                    <TextField
                        className={classes.TextField}
                        variant='filled'
                        margin='dense'
                        required
                        fullWidth
                        type='number'
                        name="mobile"
                        label="09*******"
                        id="Phone number"
                        onChange={e => props.phoneInput(e.target.value)}
                    // InputProps={{
                    //     startAdornment: <InputAdornment position="start">+95</InputAdornment>,
                    //   }}
                    />
                </form>
            </div>
        </Container>
    );
}

