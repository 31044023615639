import React from 'react';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';

export default function E() {
    return (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}>
            <RemoveShoppingCartIcon style={{ fontSize: 80, color: '#424242' }} />
            <br />
              No products found

        </div>
    );
}
