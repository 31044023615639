import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { AppState } from "../store";
import { isMobile, checkFooterIcon } from "../util";
import { Colors } from '../colors';
import { cartToogleAction, menuToogleAction } from "../redux";
import {
    makeStyles,
    IconButton,
    Badge
} from "@material-ui/core";
import {
    ShoppingBasket as ShoppingBasketIcon,
    Info as InfoIcon,
    Home as HomeIcon,
    MenuBook as MenuBookIcon,

} from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        position: 'fixed',
        bottom: 0,
        left: 0,
        padding: 'auto 0',
        width: '100%',
        height: 60,
        border: '1px solid #e0e0e0',
        background: 'white',
        zIndex: 9999
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',

    },
    iconBtn: {
        padding: 5,
        color: Colors.primary
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    txt: {
        color: 'black',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        }
    }

}))

export default function Footer() {
    const mobile = isMobile()
    const dispatch = useDispatch()
    const history = useHistory()
    const classes = useStyles();
    const { CartState } = useSelector(state => state) as AppState
    const handleMenuDrawer = () => {
        if (mobile) {
            dispatch(menuToogleAction())
        } else {
            history.push('/')
        }
    }
    const handleCartDrawer = () => {
        dispatch(cartToogleAction())
    }

    return (
        <div className={classes.root}>
            <footer className={classes.footer}>
                <div className={classes.item}>
                    <IconButton className={classes.iconBtn} onClick={handleMenuDrawer}>
                        {
                            checkFooterIcon() ? <MenuBookIcon /> : <HomeIcon />
                        }
                    </IconButton>
                    <div className={classes.txt}>{mobile ? 'MENU' : 'HOME'}</div>

                </div>
                <div className={classes.item}>
                    <IconButton className={classes.iconBtn} onClick={() => history.push('/about')}>
                        <InfoIcon />
                    </IconButton>
                    <div className={classes.txt}>ABOUT</div>
                </div>

                <div className={classes.item} onClick={() => handleCartDrawer()}>
                    <IconButton className={classes.iconBtn}>
                        {/* <IconButton aria-label="show cart notifications"> */}
                        {/* <Badge badgeContent={CartSate.items.length} color="secondary"> */}
                        <Badge badgeContent={CartState.items.length}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            color='secondary'
                            style={{ right: -3 }}>
                            <ShoppingBasketIcon />
                        </Badge>

                        {/* <Badge /> */}
                    </IconButton>
                    <div className={classes.txt}>CART</div>
                </div>
            </footer>
        </div >

    )
}